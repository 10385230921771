export const courseCardsMock = [
  {
    courseId: 1,
    imageUrl: require('@/assets/img/student-revamp/1_1konwersacje_letstalk.png'),
    iconUrl: require('@/assets/img/course-image.svg'),
  },
  {
    courseId: 2,
    imageUrl: require('@/assets/img/student-revamp/1_1konwersacje_letstalk_dorosli.png'),
    iconUrl: require('@/assets/img/course-image.svg'),
  },
  {
    courseId: 3,
    imageUrl: require('@/assets/img/student-revamp/1_1jezykangielski_korepetycje.png'),
    iconUrl: require('@/assets/img/course-image.svg'),
  },
  {
    courseId: 4,
    imageUrl: require('@/assets/img/student-revamp/1_1jezykangielski_superuczen.png'),
    iconUrl: require('@/assets/img/course-image.svg'),
  },
  {
    courseId: 5,
    imageUrl: require('@/assets/img/student-revamp/1_1jezykangielski_egzaminosmoklasisty.png'),
    iconUrl: require('@/assets/img/course-image.svg'),
  },
  {
    courseId: 6,
    imageUrl: require('@/assets/img/student-revamp/1_1jezykangielski_matura.png'),
    iconUrl: require('@/assets/img/course-image.svg'),
  },
  {
    courseId: 7,
    imageUrl: require('@/assets/img/student-revamp/1_1businessenglish.png'),
    iconUrl: require('@/assets/img/course-image.svg'),
  },
  {
    courseId: 8,
    imageUrl: require('@/assets/img/student-revamp/1_1matematyka_korepetycje.png'),
    iconUrl: require('@/assets/img/1_1ico_matematyka.svg'),
  },
  {
    courseId: 9,
    imageUrl: require('@/assets/img/student-revamp/1_1matematyka_egzaminosmoklasisty.png'),
    iconUrl: require('@/assets/img/1_1ico_matematyka.svg'),
  },
  {
    courseId: 10,
    imageUrl: require('@/assets/img/student-revamp/1_1matematyka_matura.png'),
    iconUrl: require('@/assets/img/1_1ico_matematyka.svg'),
  },
  {
    courseId: 11,
    imageUrl: require('@/assets/img/student-revamp/1_1matematyka_superuczen.png'),
    iconUrl: require('@/assets/img/1_1ico_matematyka.svg'),
  },
  {
    courseId: 12,
    imageUrl: require('@/assets/img/student-revamp/1_1jezykpolski_egzaminosmoklasisty.png'),
    iconUrl: require('@/assets/img/1_1ico_polski.svg'),
  },
  {
    courseId: 13,
    imageUrl: require('@/assets/img/student-revamp/1_1jezykpolski_matura.png'),
    iconUrl: require('@/assets/img/1_1ico_polski.svg'),
  },
  {
    courseId: 14,
    imageUrl: require('@/assets/img/student-revamp/1_1korepetycje_jezykpolski.png'),
    iconUrl: require('@/assets/img/1_1ico_polski.svg'),
  },
  {
    courseId: 30,
    imageUrl: require('@/assets/img/student-revamp/1_1biologia.png'),
    iconUrl: require('@/assets/img/1_1ico_biologia.svg'),
  },
  {
    courseId: 31,
    imageUrl: require('@/assets/img/student-revamp/1_1chemia.png'),
    iconUrl: require('@/assets/img/1_1ico_chemia.svg'),
  },
  {
    courseId: 32,
    imageUrl: require('@/assets/img/student-revamp/1_1fizyka.png'),
    iconUrl: require('@/assets/img/1_1ico_fizyka.svg'),
  },
  {
    courseId: 33,
    imageUrl: require('@/assets/img/student-revamp/1_1biologia.png'),
    iconUrl: require('@/assets/img/1_1ico_biologia.svg'),
  },
  {
    courseId: 34,
    imageUrl: require('@/assets/img/student-revamp/1_1chemia.png'),
    iconUrl: require('@/assets/img/1_1ico_chemia.svg'),
  },
  {
    courseId: 35,
    imageUrl: require('@/assets/img/student-revamp/1_1fizyka.png'),
    iconUrl: require('@/assets/img/1_1ico_fizyka.svg'),
  },
];
