
import { DateTime } from 'ts-luxon';
import { ProfileType, CourseType } from '../types/enum';
import {
  Notification,
  Option,
  TutorBasicInformation,
} from '../types/interfaces';
import slugs from '../locales/slugs';
import { TBtn } from '../node_modules/@musicmore/tutore-components/';
import SafeHtml from './SafeHtml.vue';

export default {
  name: 'ToolbarComponent',
  components: {
    TBtn,
    SafeHtml,
  },
  data() {
    return {
      ProfileType,
      CourseType,
      scrollPosition: null as null | number,
      paymentExpanded: false,
      showDialog: false,
      tab: '',
      notifications: [] as Notification[],
      unreadNotifications: [] as Notification[],
      lang: [{ text: 'PL' }, { text: 'EN' }],
      profileOptions: [] as Option[],
      bottomNavigationValue: 0,
      currentRole: this.$store.state.profile.profile.role,
      finishLoading: false,
      slug: slugs as any,
      tutorInformation: {} as TutorBasicInformation,
      firstLetters: '',
    };
  },
  computed: {
    showTeacherDayBaner(): boolean {
      return this.$store.state.common.showTeacherDayBaner;
    },
    minHeight(): string {
      if (!this.$vuetify.breakpoint.smAndUp) {
        return 'min-height: 140px;';
      }
      return 'min-height: 102px;';
    },
    height(): string {
      if (!this.$vuetify.breakpoint.smAndUp) {
        return 'min-height: 140px;';
      }
      return 'min-height: 102px;';
    },
    isActiveDot() {
      return {
        active:
          (!this.$route.path.includes('my-profile') &&
            (this.$route.path.indexOf('pl/dashboard') === 1 ||
              this.$route.path.indexOf('en/dashboard') === 1)) ||
          (!this.$route.path.includes('my-profile') &&
            this.$route.path.indexOf('settings') === 1),
      };
    },
    account() {
      return this.$store.state.account.account;
    },
    profile() {
      return this.$store.state.profile.profile;
    },
    shouldShowIndividualCoursesTab(): boolean {
      return this.$config.environment !== 'production';
    },
    toolbarHeight() {
      return this.$vuetify.breakpoint.smAndUp ? '72' : '64';
    },
    xsShadow(): string {
      return this.scrollPosition &&
        this.scrollPosition > 10 &&
        this.$vuetify.breakpoint.smAndDown
        ? 'box-shadow: 0px 8px 16px 4px rgba(130, 49, 137, 0.04) !important;'
        : '';
    },
    scienceTextStyle(): string {
      return `
            font-size: ${this.scienceButtonStyle(
              '/dashboard/my-profile',
              ProfileType.CLIENT,
              '/dashboard',
              ProfileType.STUDENT,
              '18px',
              '12px',
            )};
            line-height: ${this.scienceButtonStyle(
              '/dashboard/my-profile',
              ProfileType.CLIENT,
              '/dashboard',
              ProfileType.STUDENT,
              '10px',
              '12px',
            )};
            color: ${this.scienceButtonStyle(
              '/dashboard/my-profile',
              ProfileType.CLIENT,
              '/dashboard',
              ProfileType.STUDENT,
              '#82318',
              '#1d1d4e',
            )};`;
    },
    logoTutoreIcon() {
      return require('@/assets/logo-tutore.svg');
    },
    logoWidth() {
      return (this.$vuetify.breakpoint.smAndUp &&
        !this.$vuetify.breakpoint.lgAndUp) ||
        this.$vuetify.breakpoint.xsOnly
        ? '118'
        : '200';
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    hideNavbar() {
      if (this.$route.path.includes(`${this.$i18n.locale}/enroll-individual`)) {
        return 'opacity-0';
      } else {
        return '';
      }
    },
    isTutorCompleteRegistration() {
      return this.$store.state.account.isTutorCompleteRegistration;
    },
    currentDate(): DateTime {
      return DateTime.now();
    },
    septemberFirst(): DateTime {
      return DateTime.fromObject({ month: 9, day: 1 });
    },
  },
  watch: {
    profile: {
      handler() {
        this.generateOptions();
      },
      immediate: true,
    },
    locale() {
      this.generateOptions();
    },
    account: {
      handler() {
        if (this.account) {
          this.generateOptions();
          // this.getNotifications();
        }
      },
      immediate: true,
    },
    $route() {
      // if (this.account) {
      //   this.getNotifications();
      // }
    },
  },
  async created() {
    await this.getTutorInformation();
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
    this.finishLoading = true;
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    generateOptions() {
      this.profileOptions = [];
      if (this.profile.role === this.ProfileType.TEACHER) {
        if (process.client) {
          this.profileOptions.push({
            text: this.$t('PAGES.DASHBOARD.TEACHER.EDIT_PROFILE') as string,
            redirectTo: `/${this.$i18n.locale}/onboarding${
              this.isTutorCompleteRegistration === 'false' ||
              this.isTutorCompleteRegistration === null
                ? ''
                : '?action=edit'
            }`,
          });
        }
      } else if (this.profile.role !== this.ProfileType.STUDENT) {
        this.profileOptions.push({
          text: this.$t('COMPONENTS.TOOLBAR.MENU.SETTINGS') as string,
          redirectTo: `/${this.$i18n.locale}/settings/`,
        });
      } else if (this.currentDate <= this.septemberFirst) {
        this.profileOptions.push([
          // {
          //   text: this.$t('ENROLL.PASSIVE.MENU.TITLE').toString(),
          //   redirectTo: `/${this.$i18n.locale}/payment-overview/`,
          // },
          {
            text: this.$t('PROFILES.HOLIDAYS_BREAK.TITLE_1').toString(),
            redirectTo: `/${this.$i18n.locale}/holiday-break/`,
          },
        ]);
      }
      let options = [];
      if (
        this.profile.role === this.ProfileType.CLIENT &&
        this.currentDate <= this.septemberFirst
      ) {
        options = [
          // {
          //   text: this.$t('ENROLL.PASSIVE.MENU.TITLE').toString(),
          //   redirectTo: `/${this.$i18n.locale}/payment-overview/`,
          // },
          {
            text: this.$t('PROFILES.HOLIDAYS_BREAK.TITLE_1').toString(),
            redirectTo: `/${this.$i18n.locale}/holiday-break/`,
          },
          {
            text: this.$t('COMPONENTS.TOOLBAR.MENU.REFER') as string,
            redirectTo: `/${this.$i18n.locale}/referral-summary/`,
          },
          {
            text: this.$t('COMPONENTS.TOOLBAR.MENU.HELP') as string,
            redirectTo: `/${this.$i18n.locale}${
              this.slug.Help.slug[this.$i18n.locale as 'pl' | 'en']
            }`,
          },
          {
            text: this.$t('COMPONENTS.TOOLBAR.MENU.PRIVACY') as string,
            redirectTo: `/${this.$i18n.locale}/polityka-prywatnosci-tutore-poland/`,
          },
          {
            text: this.$t('COMPONENTS.TOOLBAR.MENU.DOCUMENTS') as string,
            redirectTo: `/${this.$i18n.locale}${
              this.slug.DocumentsTutorePoland.slug[
                this.$i18n.locale as 'pl' | 'en'
              ]
            }/`,
          },
          {
            text: this.$t('COMPONENTS.TOOLBAR.MENU.LOGOUT') as string,
            logOut: true,
          },
        ];
      } else {
        options = [
          {
            text: this.$t('COMPONENTS.TOOLBAR.MENU.HELP') as string,
            redirectTo: `/${this.$i18n.locale}${
              this.slug.Help.slug[this.$i18n.locale as 'pl' | 'en']
            }`,
          },
          // {
          //   text: this.$t('ENROLL.PASSIVE.MENU.TITLE').toString(),
          //   redirectTo: `/${this.$i18n.locale}/payment-overview/`,
          // },
          {
            text: this.$t('COMPONENTS.TOOLBAR.MENU.PRIVACY') as string,
            redirectTo: `/${this.$i18n.locale}/polityka-prywatnosci-tutore-poland/`,
          },
          {
            text: this.$t('COMPONENTS.TOOLBAR.MENU.DOCUMENTS') as string,
            redirectTo: `/${this.$i18n.locale}${
              this.slug.DocumentsTutorePoland.slug[
                this.$i18n.locale as 'pl' | 'en'
              ]
            }/`,
          },
          {
            text: this.$t('COMPONENTS.TOOLBAR.MENU.LOGOUT') as string,
            logOut: true,
          },
        ];
      }
      this.profileOptions.push(...options);
    },
    signOut() {
      this.showDialog = false;
      this.$store.dispatch('account/signOut', null);
      if (process.client) {
        localStorage.setItem('profile/profiles', JSON.stringify([]));
        localStorage.removeItem('profile');
        document.cookie = `profile=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${this.$config.websiteUrl.replace(
          'https://',
          '',
        )}`;
      }
      this.$store.commit('profile/setProfiles', []);
      this.$store.commit('profile/setProfile', {});
    },
    async getNotifications() {
      this.$http
        .get('/messages/profile')
        .then((response) => {
          this.notifications = this.$helpers.sortNotificationsByDate(
            response.data,
            true,
          );
          this.unreadNotifications = this.$helpers.sortNotificationsByDate(
            response.data.filter(
              (notification: Notification) => notification.read === 0,
            ),
            true,
          );
          this.$forceUpdate();
          const index = this.notifications.findIndex(
            (notification: Notification) => notification.read === 0,
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    markAsRead(notification: Notification) {
      const notificationId = [];
      notificationId.push(notification.id);
      this.$http
        .post('/messages/read', { messageIds: notificationId })
        .then(() => {
          this.notifications.filter(
            (x) => x.id === notification.id,
          )[0].read = 1;
          this.unreadNotifications.pop();
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    markAllAsRead() {
      if (this.notifications.length > 0) {
        const notificationsId = this.notifications.map(
          (notification: Notification) => notification.id,
        );
        this.$http
          .post('/messages/read', { messageIds: notificationsId })
          .then(() => {
            for (let i = 0; i < this.notifications.length; i++) {
              this.notifications[i].read = 1;
            }
            this.$forceUpdate();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.unreadNotifications = [];
    },
    showAllNotifications() {
      this.$router.push({
        path: '/' + this.$root.$i18n.locale + '/notifications/',
      });
    },
    redirectTo(redirectTo: string | undefined): void {
      if (redirectTo && redirectTo.includes('polityka-prywatnosci')) {
        window.open(
          `${this.$config.websiteUrl}/${this.$nuxt.$i18n.locale}/polityka-prywatnosci-tutore-poland`,
          '_blank',
        );
      } else if (redirectTo && this.$route.path !== redirectTo) {
        this.$router.push(redirectTo);
      }
    },
    countTimeDiff(inputDate: string) {
      const now = new Date();
      const then = new Date(inputDate);
      const differenceInMs = now.getTime() - then.getTime();
      const differenceInSeconds = differenceInMs / 1000;
      const differenceInMinutes = differenceInSeconds / 60;
      if (differenceInMinutes <= 1) {
        return this.$t('COMMON.TIME.NOW').toString();
      }
      if (differenceInMinutes <= 60) {
        return this.getMinutesTextVariation(Math.floor(differenceInMinutes));
      }
      if (differenceInMinutes <= 90) {
        return `1 ${this.$t('COMMON.TOOLBAR.HOURS.1').toString()}`;
      }
      if (differenceInMinutes <= 120) {
        return `2 ${this.$t('COMMON.TOOLBAR.HOURS.2').toString()}`;
      }
      if (differenceInMinutes <= 180) {
        return `3 ${this.$t('COMMON.TOOLBAR.HOURS.2').toString()}`;
      }
      if (differenceInMinutes <= 240) {
        return `4 ${this.$t('COMMON.TOOLBAR.HOURS.2').toString()}`;
      }
      if (differenceInMinutes <= 300) {
        return `5 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 360) {
        return `6 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 420) {
        return `7 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 480) {
        return `8 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 540) {
        return `9 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 600) {
        return `10 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 660) {
        return `11 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 720) {
        return `12 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 780) {
        return `13 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 840) {
        return `14 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 900) {
        return `15 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 960) {
        return `16 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 1020) {
        return `17 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 1080) {
        return `18 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 1140) {
        return `19 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 1200) {
        return `20 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 1260) {
        return `21 ${this.$t('COMMON.TOOLBAR.HOURS.3').toString()}`;
      }
      if (differenceInMinutes <= 1320) {
        return `22 ${this.$t('COMMON.TOOLBAR.HOURS.2').toString()}`;
      }
      if (differenceInMinutes <= 1380) {
        return `23 ${this.$t('COMMON.TOOLBAR.HOURS.2').toString()}`;
      }
      const differenceInHours = differenceInMinutes / 60;
      if (differenceInHours < 32) {
        return `1 ${this.$t('COMMON.TOOLBAR.DAYS.1').toString()}`;
      }
      if (differenceInHours < 48) {
        return `2 ${this.$t('COMMON.TOOLBAR.DAYS.2').toString()}`;
      }
      if (differenceInHours < 64) {
        return `3 ${this.$t('COMMON.TOOLBAR.DAYS.2').toString()}`;
      }
      if (differenceInHours < 80) {
        return `4 ${this.$t('COMMON.TOOLBAR.DAYS.2').toString()}`;
      }
      if (differenceInHours < 96) {
        return `5 ${this.$t('COMMON.TOOLBAR.DAYS.2').toString()}`;
      }
      if (differenceInHours < 112) {
        return `6 ${this.$t('COMMON.TOOLBAR.DAYS.2').toString()}`;
      }
      const differenceInDays = differenceInHours / 24;
      if (differenceInDays < 14) {
        return `2 ${this.$t('COMMON.TOOLBAR.WEEKS.1').toString()}`;
      }
      if (differenceInDays < 21) {
        return `3 ${this.$t('COMMON.TOOLBAR.WEEKS.1').toString()}`;
      }
      if (differenceInDays < 28) {
        return `4 ${this.$t('COMMON.TOOLBAR.WEEKS.1').toString()}`;
      }
      if (differenceInDays < 35) {
        return `5 ${this.$t('COMMON.TOOLBAR.WEEKS.2').toString()}`;
      }
      if (differenceInDays < 42) {
        return `6 ${this.$t('COMMON.TOOLBAR.WEEKS.2').toString()}`;
      }
      if (differenceInDays < 49) {
        return `7 ${this.$t('COMMON.TOOLBAR.WEEKS.2').toString()}`;
      }
      if (differenceInDays < 56) {
        return `8 ${this.$t('COMMON.TOOLBAR.WEEKS.2').toString()}`;
      }
      const differenceInWeeks = differenceInDays / 7;
      if (differenceInWeeks < 9) {
        return `3 ${this.$t('COMMON.TOOLBAR.MONTH.1').toString()}`;
      }
      if (differenceInWeeks < 13) {
        return `4 ${this.$t('COMMON.TOOLBAR.MONTH.1').toString()}`;
      }
      if (differenceInWeeks < 17) {
        return `5 ${this.$t('COMMON.TOOLBAR.MONTH.2').toString()}`;
      }
      if (differenceInWeeks < 21) {
        return `6 ${this.$t('COMMON.TOOLBAR.MONTH.2').toString()}`;
      }
      if (differenceInWeeks < 25) {
        return `7 ${this.$t('COMMON.TOOLBAR.MONTH.2').toString()}`;
      }
      if (differenceInWeeks < 29) {
        return `8 ${this.$t('COMMON.TOOLBAR.MONTH.2').toString()}`;
      }
      if (differenceInWeeks < 33) {
        return `9 ${this.$t('COMMON.TOOLBAR.MONTH.2').toString()}`;
      }
      if (differenceInWeeks < 37) {
        return `10 ${this.$t('COMMON.TOOLBAR.MONTH.2').toString()}`;
      }
      if (differenceInWeeks < 41) {
        return `11 ${this.$t('COMMON.TOOLBAR.MONTH.2').toString()}`;
      }
      return this.$t('COMMON.TOOLBAR.YEAR_AGO').toString();
    },
    getMinutesTextVariation(number: number) {
      const input = number.toString();
      let text = '';
      switch (input) {
        case '1':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.1').toString()}`;
          break;
        case '2':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '3':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '4':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '22':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '23':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '24':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '32':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '33':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '34':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '42':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '43':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '44':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '52':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '53':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        case '54':
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.2').toString()}`;
          break;
        default:
          text = `${input} ${this.$t('COMMON.TOOLBAR.MINUTES.3').toString()}`;
          break;
      }
      return text;
    },
    routerStyles(routerPath: string, ifTrue: string, ifFalse: string) {
      return this.$router.currentRoute.path
        .replace('/en', '')
        .replace('/pl', '') === routerPath
        ? ifTrue
        : ifFalse;
    },
    scienceButtonStyle(
      firstPath: string,
      firstType: string,
      secondPath: string,
      secondType: string,
      ifTrue: string,
      ifFalse: string,
    ) {
      const routePath = this.$route.path.replace('/en', '').replace('/pl', '');
      return (routePath === firstPath && this.profile.role === firstType) ||
        (routePath === secondPath && this.profile.role === secondType)
        ? ifTrue
        : ifFalse;
    },
    textStyle(path: string) {
      return `font-size: ${this.routerStyles(
        path,
        '18px',
        '12px',
      )}; line-height: ${this.routerStyles(
        path,
        '10px',
        '12px',
      )}; color: ${this.routerStyles(path, '#823189', '#1d1d4e')};`;
    },
    async getTutorInformation() {
      if (this.profile.role === this.ProfileType.TEACHER) {
        this.$http
          .get('/tutors/information')
          .then(async (response) => {
            this.tutorInformation.firstName =
              response.data.basicInformation?.firstName;
            this.tutorInformation.lastName =
              response.data.basicInformation?.lastName;
            this.getFirstLetters();
          })
          .catch((err) => {
            console.log('catch');
          });
      }
    },
    getFirstLetters() {
      if (this.tutorInformation.firstName && this.tutorInformation.lastName) {
        const firstLetter = this.tutorInformation.firstName[0].toUpperCase();
        const secondLetter = this.tutorInformation.lastName[0].toUpperCase();
        const joinLetters = firstLetter + secondLetter;
        this.firstLetters = joinLetters;
      }
    },
  },
};
